import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Web 3.0",
          "UX/UI",
          "Cloud Artitecture",
          "Fullstack Development",
          "NFT drops",
          "Graphics Design"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
