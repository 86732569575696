import React from "react";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <Container>
          <Row>
            <Col md={12} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>
                LET US <span className="purple"> INTRODUCE </span> OURSELVES
              </h1>
              <p className="home-about-body">
                We build
                <i>
                  <b className="purple"> amazing experiences </b>
                </i>
                for you!
                <br />
                <br />
                <b>Engage with your customers, </b>without the overhead.
                <br />
                <br />
                <i>
                  <b className="purple">Attract </b>
                </i>- We build attractive web apps, and solutions that just work, 100% of the time.
                <br />
                <br />
                <i>
                  <b className="purple">Scale </b>
                </i>- Delivering to a large audience? We build scale in by default, so you don't have to worry.
                <br />
                <br /><i>
                  <b className="purple">Innovate </b>
                </i>- Bring your vision to life, we will cross any bridge to bring your dream to life!
              </p>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
